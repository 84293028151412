<template>
  <nav
    v-show="isVisible"
    id="navigation"
    class="main-navigation"
    :aria-expanded="isVisible"
    aria-label="Main Navigation"
    :class="{ '-visible': isVisible, '-open': isOpen }"
  >
    <ul class="navigation -main" v-if="mainNavigation">
      <li
        class="item"
        v-for="(navItem, index) in mainNavigation.navLinks"
        :key="`navitem-${index}`"
      >
        <NuxtLink
          v-if="navItem.link?.slug"
          class="link nav-link-default"
          :to="getPageLink(navItem.link.slug)"
        >
          {{ navItem.label }}
        </NuxtLink>
      </li>
    </ul>
    <ul class="navigation -services">
      <p class="title">Bereiche</p>
      <li class="item" v-for="(service, index) in allServices" :key="`service-${index}`">
        <NuxtLink v-if="service" class="link nav-link-default" :to="`/services/${service.slug}`">
          {{ service.title }}
        </NuxtLink>
      </li>
    </ul>
    <ul class="links" v-if="headerContent">
      <li
        class="item"
        v-for="(l, index) in headerContent.contactLinks"
        :key="`contact-link-${index}`"
      >
        <DatoLink class="link nav-link-default" :data="l" />
      </li>
    </ul>
    <DatoAsset
      :lazy-load="true"
      class="asset"
      v-if="headerContent"
      :asset="headerContent && headerContent.navigationAsset"
      :data="headerContent.navigationAsset"
    />
  </nav>
</template>

<script setup lang="ts">
import { useScrollLock } from '@vueuse/core';
import gsap from 'gsap';
import type { GetHeaderQuery, GetMainNavigationQuery, GetServicesQuery } from '#gql';
import DatoLink from '~/dato/DatoLink.vue';
import DatoAsset from '~/dato/DatoAsset.vue';

const bodyElement = ref<HTMLElement | null>(null);
const isLocked = useScrollLock(bodyElement);

const tl = gsap.timeline({
  paused: true
});

const animationDuration = 0.6;

const props = defineProps<{
  isVisible: boolean;
  isOpen: boolean;
}>();

const { data: dataHeader } = await useAsyncGql({
  operation: 'GetHeader'
});

const { data: dataMainNavigation } = await useAsyncGql({
  operation: 'GetMainNavigation'
});

const { data: dataServices } = await useAsyncGql({
  operation: 'GetServices'
});

const headerContent = (dataHeader.value as GetHeaderQuery).header;
const mainNavigation = (dataMainNavigation.value as GetMainNavigationQuery).mainNavigation;
const allServices = (dataServices.value as GetServicesQuery).allServices;

watchEffect(function () {
  if (props.isOpen) {
    isLocked.value = true;
    tl.play(0);
  } else {
    isLocked.value = false;
    tl.reverse();
  }
});

onMounted(function () {
  bodyElement.value = document.body;

  tl.fromTo(
    '.main-navigation > .navigation',
    {
      y: '100%',
      opacity: 0
    },
    {
      y: 0,
      opacity: 1,
      ease: 'power2.out',
      duration: animationDuration
    }
  )
    .fromTo(
      '.main-navigation > .links',
      {
        y: '100%',
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        ease: 'power2.out',
        duration: animationDuration
      },
      '<'
    )
    .fromTo(
      '.main-navigation > .asset',
      {
        y: '-100%',
        opacity: 0
      },
      {
        y: 0,
        opacity: 1,
        ease: 'power2.out',
        duration: animationDuration
      },
      '<'
    );
});

function getPageLink(slug: string) {
  if (slug === 'home') {
    return '/';
  }
  return `/${slug}`;
}
</script>

<style scoped lang="scss">
.main-navigation {
  position: fixed;
  inset: 0;
  display: grid;
  z-index: 20;
  padding: 0 var(--base-component-padding-inline) 2rem var(--base-component-padding-inline);
  padding-top: 4.75rem;
  background-color: var(--color-off-white);
  color: var(--color-black);
  overflow-y: auto;

  @media (--vs) {
    grid-template-rows: auto 1fr auto;
    gap: 2.5rem;
  }

  @media (--vl) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto auto auto;
  }

  &[aria-expanded='true'] {
    @media (--vl) {
      gap: 2.5rem 1rem;
    }
  }

  &[aria-expanded='false'] {
    pointer-events: none;
  }

  > .navigation.-main {
    grid-area: 1 / 1 / 2 / 2;

    @media (--vs) {
      align-self: center;
      padding-top: 4rem;
    }

    @media (--vl) {
      align-self: flex-end;
    }

    > .item {
      > .link {
        text-decoration: none;
        display: inline-block;
        line-height: 1 !important;

        @media (--vs) {
          @include text-2xl(700);

          margin: 0.75rem 0;
        }

        @media (--vl) {
          @include text-2xl(700);

          margin: 0.75rem 0;
        }
      }
    }
  }

  > .navigation.-services {
    grid-area: 2 / 1 / 3 / 2;

    @media (--vl) {
      align-self: flex-start;
    }

    > .title {
      color: var(--color-grey);
      padding-bottom: 0.5rem;

      @media (--vs) {
        @include text-lg(700);
      }

      @media (--vl) {
        @include text-md(700);
      }
    }

    > .item {
      > .link {
        text-decoration: none;
        display: inline-block;
        line-height: 1 !important;

        @media (--vs) {
          @include text-2xl(700);

          font-size: 2rem !important;
          line-height: 1.5 !important;
          margin: 0.3rem 0;
        }

        @media (--vl) {
          @include text-xl(700);

          margin: 0.6rem 0;
        }
      }
    }
  }

  > .links {
    grid-area: 3 / 1 / 4 / 2;
    align-self: end;

    @media (min-aspect-ratio: 20/10) {
      display: none;
    }

    > .item:not(:last-of-type) {
      padding-bottom: 0.5rem;
    }
  }

  > .asset {
    @media (--vs) {
      display: none;
    }

    @media (--vl) {
      grid-area: 1 / 2 / 3 / 3;
      align-self: center;
    }

    > .image {
      height: 100%;
    }
  }
}
</style>
