import type { Ref } from 'vue';

export default function useIntersectionObserver(el: Ref<null | HTMLElement>) {
  const options = {
    rootMargin: '0px',
    threshold: 0.33
  };

  const isElementVisible = ref<boolean>(false);
  const observer = ref<null | IntersectionObserver>(null);

  onMounted(function () {
    observer.value = new IntersectionObserver(onIntersect, options);
  });

  return {
    start,
    stop,
    isElementVisible
  };

  function onIntersect(payload: IntersectionObserverEntry[]) {
    const e = payload[0];

    isElementVisible.value = e.isIntersecting;

    if (isElementVisible.value) {
      stop();
    }
  }

  function stop() {
    if (el.value && observer.value) {
      observer.value.unobserve(el.value);
    }
  }

  function start() {
    if (el.value && observer.value) {
      observer.value.observe(el.value);
    }
  }
}
