<template>
  <div class="app-error">
    <SiteHeader theme="dark" />
    <main class="main">
      <div class="content">
        <h1 class="title">{{ general?.pageNotFound ?? 'Seite nicht gefunden' }}</h1>
        <button @click="handleError()" class="button-big">
          <span class="arrow"></span>
          {{ general?.backToHome ?? 'Zurück zur Startseite' }}
        </button>
      </div>
    </main>
    <SiteFooter theme="light" />
  </div>
</template>

<script setup lang="ts">
import SiteHeader from '~/components/site-header/SiteHeader.vue';
import type { GetGeneralQuery } from '#gql';

const { data } = await useAsyncGql({
  operation: 'GetGeneral'
});

const general = (data.value as GetGeneralQuery).general;

function handleError() {
  // clearError();
  clearError({ redirect: '/' });
}
</script>

<style scoped lang="scss">
.app-error {
  background-color: var(--color-off-white);
  color: var(--color-black);
  height: 100svh;

  > .main {
    padding: 8.25rem var(--base-component-padding-inline) 0 var(--base-component-padding-inline);

    @media (--vs) {
      height: calc(100svh - 13rem);
    }

    @media (--vl) {
      height: calc(100svh - 13.25rem);
      display: flex;
      align-items: center;
    }

    > .content {
      > .title {
        @media (--vs) {
          @include text-3xl(700);

          padding-bottom: 1.5rem;
        }

        @media (--vl) {
          @include text-4xl(700);

          padding-bottom: 5rem;
        }
      }
    }
  }
}
</style>
