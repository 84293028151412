<template>
  <NuxtLink
    v-if="data.linkType === 'internal'"
    :to="'/' + data.internalUrl?.slug"
    class="dato-link"
    :target="data.openLinkInNewWindow ? '_blank' : '_self'"
    :aria-label="data.linkText"
    >{{ data.linkText }}
  </NuxtLink>
  <a
    v-else-if="data.linkType === 'external'"
    :href="data.externalUrl"
    class="dato-link"
    :target="data.openLinkInNewWindow ? '_blank' : '_self'"
    :aria-label="data.linkText"
    >{{ data.linkText }}
  </a>
  <a
    v-else-if="data.linkType === 'email'"
    :href="transformedEmailUrl"
    class="dato-link"
    :target="data.openLinkInNewWindow ? '_blank' : '_self'"
    :aria-label="data.linkText"
    >{{ data.linkText }}
  </a>
  <a
    v-else-if="data.linkType === 'tel'"
    :href="transformedTelUrl"
    class="dato-link"
    :target="data.openLinkInNewWindow ? '_blank' : '_self'"
    :aria-label="data.linkText"
    >{{ data.linkText }}
  </a>
</template>

<script setup lang="ts">
import type { LinkFragment } from '#gql';

const props = defineProps<{
  data: LinkFragment;
}>();

const transformedTelUrl = computed(function () {
  if (props.data.telUrl) {
    if (props.data.telUrl.substring(0, 4) === 'tel:') {
      return props.data.telUrl;
    }
    return `tel:${props.data.telUrl}`;
  }

  return null;
});

const transformedEmailUrl = computed(function () {
  if (props.data.emailUrl) {
    if (props.data.emailUrl.substring(0, 6) === 'mailto:') {
      return props.data.emailUrl;
    }
    return `mailto:${props.data.emailUrl}`;
  }

  return null;
});
</script>

<style scoped lang="scss"></style>
