import type { Ref } from 'vue';

export function useCopyright(imageEl: Ref<null | HTMLElement>) {
  const { x, y, top, right, bottom, left, width, height } = useElementBounding(imageEl);

  const positionTop = computed(function () {
    return height.value;
  });

  const isOpen = ref<boolean>(false);

  return {
    top: positionTop,
    toggle,
    isOpen
  };

  function toggle(open = !isOpen.value) {
    isOpen.value = open;
  }
}
