<template>
  <footer
    class="site-footer"
    v-if="data.footer"
    :class="{ '-dark': darkThemed, '-light': !darkThemed }"
  >
    <address class="address">
      <p class="name">{{ companyName }}</p>
      <div v-for="line in addressLines" :key="line.id">
        <p class="line" v-if="line.link">
          <DatoLink class="link nav-link-default" :data="line.link" />
        </p>
        <p class="line" v-else>{{ line.line }}</p>
      </div>
    </address>
    <div class="legallinks">
      <div v-for="link in legalInformationLinks" :key="link.id">
        <DatoLink class="link nav-link-default" :data="link" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import type { GetFooterQuery } from '#gql';
import DatoLink from '~/dato/DatoLink.vue';

const props = defineProps<{
  theme: 'dark' | 'light';
}>();

const { data, error } = await useAsyncGql({
  operation: 'GetFooter'
});

const footer = (data.value as GetFooterQuery).footer;

const companyName = computed(function () {
  return footer?.companyName ?? '';
});

const addressLines = computed(function () {
  return footer?.addressLines ?? [];
});

const legalInformationLinks = computed(function () {
  return footer?.legalInformationLinks ?? [];
});

const darkThemed = computed(function () {
  return props.theme === 'dark';
});
</script>

<style scoped lang="scss">
.site-footer {
  display: flex;
  padding: var(--base-component-padding);
  gap: 1.5rem;
  margin-right: var(--site-outer-margin);
  margin-left: var(--site-outer-margin);

  @media (--vs) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (--vl) {
    justify-content: space-between;
  }

  &.-dark {
    color: var(--color-white);
    background-color: var(--color-black);
  }

  &.-light {
    background-color: var(--color-off-white);
    color: var(--color-black);

    > .legallinks {
      color: var(--color-grey);
    }
  }

  > .address,
  > .legallinks {
    @include text-sm(300);

    display: flex;
    gap: 2rem;

    @media (--vs) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }

    @media (--vl) {
      align-items: center;
    }
  }

  > .address {
    color: currentColor;
  }

  > .legallinks {
  }
}
</style>
