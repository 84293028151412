<template>
  <div class="app">
    <SiteHeader theme="dynamic" />
    <NuxtPage />
  </div>
</template>

<script setup lang="ts">
import type { GetGeneralQuery, GeneralFragment } from '#gql';
import SiteHeader from '~/components/site-header/SiteHeader.vue';
import { type SeoMetaTagType, toHead } from 'vue-datocms';

const { data } = await useAsyncGql({
  operation: 'GetGeneral'
});

const general = (data.value as GetGeneralQuery).general as GeneralFragment;
provide<GeneralFragment>('general', general);

const { data: siteData } = await useAsyncGql({
  operation: 'GetSiteData'
});

// @ts-ignore
useHead(toHead(siteData.value._site.faviconMetaTags));
</script>
