import { default as _91id_93AFMBzgWxKLMeta } from "/opt/render/project/src/pages/[id].vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91projekt_id_93gtazNKaJWKMeta } from "/opt/render/project/src/pages/projekte/[projekt_id].vue?macro=true";
import { default as index6J3ba2jBzMMeta } from "/opt/render/project/src/pages/projekte/index.vue?macro=true";
import { default as _91service_id_934Xu86xPSiNMeta } from "/opt/render/project/src/pages/services/[service_id].vue?macro=true";
export default [
  {
    name: _91id_93AFMBzgWxKLMeta?.name ?? "id",
    path: _91id_93AFMBzgWxKLMeta?.path ?? "/:id()",
    meta: _91id_93AFMBzgWxKLMeta || {},
    alias: _91id_93AFMBzgWxKLMeta?.alias || [],
    redirect: _91id_93AFMBzgWxKLMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91projekt_id_93gtazNKaJWKMeta?.name ?? "projekte-projekt_id",
    path: _91projekt_id_93gtazNKaJWKMeta?.path ?? "/projekte/:projekt_id()",
    meta: _91projekt_id_93gtazNKaJWKMeta || {},
    alias: _91projekt_id_93gtazNKaJWKMeta?.alias || [],
    redirect: _91projekt_id_93gtazNKaJWKMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/projekte/[projekt_id].vue").then(m => m.default || m)
  },
  {
    name: index6J3ba2jBzMMeta?.name ?? "projekte",
    path: index6J3ba2jBzMMeta?.path ?? "/projekte",
    meta: index6J3ba2jBzMMeta || {},
    alias: index6J3ba2jBzMMeta?.alias || [],
    redirect: index6J3ba2jBzMMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/projekte/index.vue").then(m => m.default || m)
  },
  {
    name: _91service_id_934Xu86xPSiNMeta?.name ?? "services-service_id",
    path: _91service_id_934Xu86xPSiNMeta?.path ?? "/services/:service_id()",
    meta: _91service_id_934Xu86xPSiNMeta || {},
    alias: _91service_id_934Xu86xPSiNMeta?.alias || [],
    redirect: _91service_id_934Xu86xPSiNMeta?.redirect || undefined,
    component: () => import("/opt/render/project/src/pages/services/[service_id].vue").then(m => m.default || m)
  }
]