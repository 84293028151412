import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetAllProjects","GetAllServices","GetFooter","GetGeneral","GetHeader","GetMainNavigation","GetPageBySlug","GetProjectBySlug","GetProjectsByService","GetServiceBySlug","GetServiceIdBySlug","GetServices","GetSiteData","ContentModules","ContentModulesProject","ContentModulesService","ContactForm","ContentText","ContentTeam","ContentGallery","GalleryDouble","GallerySingle","CtaModule","General","HeaderDefault","Link","PageMeta","Project","ResponsiveImage","SeoMetaTags","Service","TeamMember"]}
export const GqlGetAllProjects = (...params) => useGql()('GetAllProjects', ...params)
export const GqlGetAllServices = (...params) => useGql()('GetAllServices', ...params)
export const GqlGetFooter = (...params) => useGql()('GetFooter', ...params)
export const GqlGetGeneral = (...params) => useGql()('GetGeneral', ...params)
export const GqlGetHeader = (...params) => useGql()('GetHeader', ...params)
export const GqlGetMainNavigation = (...params) => useGql()('GetMainNavigation', ...params)
export const GqlGetPageBySlug = (...params) => useGql()('GetPageBySlug', ...params)
export const GqlGetProjectBySlug = (...params) => useGql()('GetProjectBySlug', ...params)
export const GqlGetProjectsByService = (...params) => useGql()('GetProjectsByService', ...params)
export const GqlGetServiceBySlug = (...params) => useGql()('GetServiceBySlug', ...params)
export const GqlGetServiceIdBySlug = (...params) => useGql()('GetServiceIdBySlug', ...params)
export const GqlGetServices = (...params) => useGql()('GetServices', ...params)
export const GqlGetSiteData = (...params) => useGql()('GetSiteData', ...params)