import type { Ref } from 'vue';

export default function useImageScaleForHover(el: Ref<null | HTMLElement>) {
  const scale = ref<number>(1);

  onMounted(function () {
    const w = el.value?.clientWidth;

    if (w) {
      scale.value = (1 / w) * (w + 7 * 2);
    }
  });

  return { scale };
}
